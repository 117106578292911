// const API_URL = 'http://127.0.0.1:8014/api/method/upeoeducation.services.rest.';
const API_URL = 'https://rakf.elimupro.com/api/method/upeoeducation.services.rest.';

const primaryColor = '#F8F8F9';
const secondaryColor = 'blue';
const titleColor = 'black';
const buttonColor = '#6E03E7';
const buttonHoverColor = '#5C02C4';
const titleFont = "Poppins,Roboto,Helvetica,Arial,sans-serif;"
module.exports = {API_URL, primaryColor, secondaryColor, titleColor, buttonColor, buttonHoverColor, titleFont}


